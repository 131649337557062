import request from "@/utils/request";

const api = {
  getDailyList: "/dsmcore/api/daily/list", // 日报信息获取
  exportWorkDaily: "/dsmcore/api/worker/workexport", // 工地列表导出
  exportEventDaily: "/dsmcore/api/worker/eventexport", // 事件报警列表导出
  downExcel: "/files/excel/file/downexcel", // 日报excel获取接口
  getDailyCount: "/dsmcore/api/daily/count", //日报
};

export function GetDailyList(parameter) {
  return request({
    url: api.getDailyList,
    method: "post",
    params: parameter,
  });
}
export function getDailyCount(parameter) {
  return request({
    url: api.getDailyCount,
    method: "post",
    params: parameter,
  });
}
