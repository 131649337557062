<template>
  <div>
    <s-search :queryItems="queryItems" @fetchData="changeParams"></s-search>

    <a-tabs
      :defaultActiveKey="defaultActive"
      @change="changeTab"
      style="text-align: left"
    >
      <!-- <a-tab-pane key="0" tab="日报"></a-tab-pane> -->
      <a-tab-pane key="1" tab="工地概况日报"></a-tab-pane>
      <a-tab-pane key="2" tab="人员考勤日报"></a-tab-pane>
      <a-tab-pane key="3" tab="报警事件日报"></a-tab-pane>
    </a-tabs>

    <div class="table-button">
      <a-button
        type="plain"
        icon="cloud-download"
        @click="handleExport"
        :disabled="exportDis"
        v-if="isShowBtns.indexOf('DeviceList-/api/device/import') > -1"
        >导出</a-button
      >
      <a-button type="plain" icon="redo" @click="fetchDailyList">刷新</a-button>
    </div>

    <daily-list-table
      :dataResult="dataResult"
      :loading="loading"
      :daily_type="daily_type"
      @changePage="changePage"
      @changeSelect="changeSelect"
      ref="DailyListTable"
    ></daily-list-table>

    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal>
  </div>
</template>

<script>
import { SSearch } from "@/components";
import { GetDailyList } from "@/api/daily";
import { getDailyCount } from "@/api/daily";
import DailyListTable from "./DailyListTable.vue";
import { urlConfig } from "@/config/config";
import LogJiuzhengVue from "../../image/LogJiuzheng.vue";

// 搜索条件
const queryItems = [
  {
    label: "分公司名称",
    value: "bc_name",
    type: "select",
    selectOption: "CompanyList",
  },
  {
    label: "日报查询日期",
    value: "timeRange",
    type: "dateRange",
    range: ["begin_time", "end_time"],
  },
];
const orderParam = [
  "bc_name",
  "begin_time",
  "end_time",
  "daily_type",
  "pageNo",
  "pageSize",
];
const orderParamNum = ["bc_id", "start_time", "end_time", "pageNo", "pageSize"];
export default {
  name: "SiteList",
  components: {
    SSearch,
    DailyListTable,
  },
  data() {
    this.queryItems = queryItems;
    return {
      defaultActive: "1",
      daily_type: "1",
      // 弹出框
      visible: false,
      confirmLoading: false,
      // 查询参数
      loading: false,
      params: {
        bc_name: "",
        begin_time: "",
        end_time: "",
      },
      queryParam: {
        pageNo: 1,
        pageSize: 10,
      },
      dataResult: {},
      selectedRows: [],
      isShowBtns: [],
    };
  },
  created() {
    this.fetchDailyList();
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  computed: {
    exportDis: function () {
      return this.selectedRows.length ? false : true;
    },
  },
  methods: {
    changeTab(val) {
      // console.log(val);
      this.queryParam.pageNo = 1;
      this.daily_type = val;

      this.$refs.DailyListTable.clearSelect();
      this.fetchDailyList();
    },
    changeParams(parameter) {
      console.log(this.parameter);
      console.log(parameter);
      this.parameter = parameter;
      console.log(parameter);
      // this.params.bc_id = parameter.bc_name;
      // this.params.start_time = parameter.begin_time;
      // this.params.end_time = parameter.end_time;
      console.log(this.params);
      this.queryParam.pageNo = 1;
      this.fetchDailyList();
    },
    changePage(pageNo, pageSize) {
      this.queryParam.pageNo = pageNo;
      this.queryParam.pageSize = pageSize;
      this.fetchDailyList();
    },
    changeSelect(rows) {
      this.selectedRows = rows;
      console.log(this.selectedRows);
    },
    // 查询点击事件
    fetchDailyList() {
      // console.log("daily_type", this.daily_type);
      if (this.daily_type == 0) {
        // getDailyCount
        let param = Object.assign(
          {},
          this.parameter,
          { daily_type: this.daily_type },
          this.queryParam
        );
        const requestParameters = this.$order(param, orderParamNum);
        console.log("requestParameters", this.parameter);
        requestParameters.bc_id = this.parameter ? this.parameter.bc_name : "";
        requestParameters.start_time = this.parameter
          ? this.parameter.begin_time
          : "";
        requestParameters.end_time = this.parameter
          ? this.parameter.end_time
          : "";
        console.log("this.params", requestParameters);
        const params = {
          // ...this.params,
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParamNum),
        };
        // console.log("params", params);
        this.loading = true;
        getDailyCount(params).then((res) => {
          if (res.data.data) {
            res.data.data.forEach((el, index) => {
              el.serial = index;
            });
            this.dataResult = res.data;
            this.$nextTick(() => {
              this.$refs.DailyListTable.dataLoad(res.data);
              this.loading = false;
            });
          }
        });
      } else {
        let param = Object.assign(
          {},
          this.parameter,
          { daily_type: this.daily_type },
          this.queryParam
        );
        const requestParameters = this.$order(param, orderParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParam),
        };
        this.loading = true;
        GetDailyList(params).then((res) => {
          if (res.data.data) {
            res.data.data.forEach((el, index) => {
              el.serial = index;
            });
            this.dataResult = res.data;
            this.$nextTick(() => {
              this.$refs.DailyListTable.dataLoad(res.data);
              this.loading = false;
            });
          }
        });
      }
    },

    // 导出
    handleExport() {
      this.visible = true;
      this.confirmLoading = false;
    },
    exportCancel() {
      this.visible = false;
    },
    exportOk() {
      this.confirmLoading = true;
      this.selectedRows.forEach((el) => {
        this.exportDownload(el.daily_file);
      });
      this.visible = false;
      this.confirmLoading = false;
    },
    exportDownload(filePath) {
      let iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.style.height = 0;
      iframe.src = `${urlConfig.baseUrl}/files/excel/file/downexcel?filePath=${filePath}`;
      document.body.appendChild(iframe);
      setTimeout(() => {
        iframe.remove();
      }, 5000);
    },
  },
};
</script>
<style lang="less" scoped></style>
