<template>
  <div>
    <!-- 日报 -->
    <div v-show="RibaoShow">
      <!-- 日期 -->
      <div class="ribao" v-for="(item, index) in dataResult.data" :key="index">
        <div class="riqi">{{ RiQi(item.date) }}</div>
        <div class="neirong">
          <div class="neirong_left">工地形象报告：</div>
          <div class="neirong_right">
            吸烟<span class="Num">{{ item.smoke }}</span
            >次、未穿工服<span class="Num">{{ item.clothes }}</span
            >次、整洁度差事件<span class="Num">{{ item.factory }}</span
            >次、摄像头歪曲<span class="Num">{{ item.camera }}</span
            >次
          </div>
        </div>
        <div class="neirong">
          <div class="neirong_left neirong_top">工地施工报告：</div>
          <div class="neirong_right neirong_top">
            无人施工工地有<span class="Num">{{ item.ws_nowork_sum }}</span
            >个、<span class="Num">{{ item.ws_nowork_ten }}</span
            >个工地累计超过<span class="Num">10</span>天无人施工、<span
              class="Num"
              >{{ item.ws_nowork_thirty }}</span
            >个工地累计<span class="Num">10-30</span>天无人施工
          </div>
        </div>
        <div class="neirong">
          <div class="neirong_left neirong_top">设备管理报告：</div>
          <div class="neirong_right neirong_top">
            共有<span class="Num">{{ item.device_off }}</span
            >个设备离线
          </div>
        </div>
      </div>
    </div>
    <!-- 人员考勤日报、报警事件日报表格 -->
    <div v-show="TableShow">
      <a-table
        ref="table"
        size="default"
        rowKey="serial"
        bordered
        :rowSelection="rowSelection"
        :pagination="false"
        :columns="columns"
        :dataSource="dataList"
        :loading="loading"
        :scroll="{ x: 1000 }"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ (pageNo - 1) * pageSize + index + 1 }}
        </span>
        <span slot="daily_time" slot-scope="text">
          {{ $Format(text) }}
        </span>
      </a-table>
    </div>
    <!-- 概况的表格 -->
    <div v-show="GaiKuangShow">
      <a-table
        ref="table"
        size="default"
        rowKey="serial"
        bordered
        :rowSelection="rowSelection"
        :pagination="false"
        :columns="columns1"
        :dataSource="dataList"
        :loading="loading"
        :scroll="{ x: 1000 }"
      >
        <!-- :scroll="{ x: 1500 }" -->
        <span slot="serial" slot-scope="text, record, index">
          {{ (pageNo - 1) * pageSize + index + 1 }}
        </span>
        <span slot="daily_time" slot-scope="text">
          {{ $Format(text) }}
        </span>
        <!-- 工地总数 -->
        <span slot="ws_sum" slot-scope="text">
          <a href="javascript:;"> {{ text }}</a>
        </span>
        <!-- 施工中工地数量 -->
        <span slot="ws_work_num" slot-scope="text">
          <a href="javascript:;"> {{ text }}</a>
        </span>
        <!-- 设备绑定 -->
        <span slot="device_bind_num" slot-scope="text">
          <a href="javascript:;"> {{ text }}</a>
        </span>
        <!-- 工地绑定及工地绑定率 -->
        <span slot="ws_bind_num" slot-scope="text">
          <a href="javascript:;"> {{ text }}</a>
        </span>
        <!-- 设备总数及设备总数率 -->
        <span slot="device_sum" slot-scope="text">
          <a href="javascript:;"> {{ text }}</a>
        </span>
        <!-- 设备在线及设备在线率 -->
        <!-- <span slot="device_online_num" slot-scope="text">
          <a href="javascript:;"> {{ text }} {{ device_online_numLV }}</a>
        </span> -->
      </a-table>
    </div>
    <a-pagination
      v-model="pageNo"
      :total="total"
      show-less-items
      show-size-changer
      @change="changePage"
      @showSizeChange="showSizeChange"
      style="margin: 16px; text-align: right"
    />
  </div>
</template>

<script>
// import { STable, Ellipsis, SSearch } from "@/components";

// 表格内容
const columns = [
  {
    title: "序号",
    scopedSlots: { customRender: "serial" },
    width: "70px",
    align: "center",
  },
  {
    title: "分公司名称", // 表头文字
    width: "110px",
    dataIndex: "bc_name",
    align: "center",
  },
  {
    title: "日报主题",
    width: "200px",
    dataIndex: "daily_title",
    align: "center",
  },
  {
    title: "时间",
    width: "150px",
    dataIndex: "daily_time",
    scopedSlots: { customRender: "daily_time" },
    align: "center",
  },
];
const columns1 = [
  {
    title: "序号",
    scopedSlots: { customRender: "serial" },
    width: "70px",
    align: "center",
  },
  {
    title: "分公司名称", // 表头文字
    width: "110px",
    dataIndex: "bc_name",
    align: "center",
  },
  {
    title: "日报主题",
    width: "200px",
    dataIndex: "daily_title",
    align: "center",
  },
  {
    title: "时间",
    width: "150px",
    dataIndex: "daily_time",
    scopedSlots: { customRender: "daily_time" },
    align: "center",
  },
  // 工地概况日报独有
  // {
  //   title: "工地总数",
  //   width: "150px",
  //   dataIndex: "ws_sum",
  //   scopedSlots: { customRender: "ws_sum" },
  //   align: "center",
  // },
  // {
  //   title: "施工中工地数量",
  //   width: "150px",
  //   dataIndex: "ws_work_num",
  //   scopedSlots: { customRender: "ws_work_num" },
  //   align: "center",
  // },
  // {
  //   title: "工地绑定",
  //   width: "150px",
  //   dataIndex: "ws_bind_num",
  //   scopedSlots: { customRender: "ws_bind_num" },
  //   customRender: (num, text) => {
  //     let content =
  //       num + " " + Number(num / text.ws_sum).toFixed(2) * 100 + "%";
  //     // Math.round((num / text.ws_sum) * Math.pow(10, 4)) / Math.pow(10, 4);
  //     return <span style="color:bulue">{content}</span>;
  //   },
  //   align: "center",
  // },
  // {
  //   title: "设备总数",
  //   width: "150px",
  //   dataIndex: "device_sum",
  //   scopedSlots: { customRender: "device_sum" },
  //   align: "center",
  // },
  // {
  //   title: "设备绑定",
  //   width: "150px",
  //   dataIndex: "device_bind_num",
  //   // scopedSlots: { customRender: "device_bind_num" },
  //   customRender: (num, text) => {
  //     let content =
  //       num + " " + Number(num / text.device_sum).toFixed(2) * 100 + "%";
  //     return content;
  //   },
  //   align: "center",
  // },
  // {
  //   title: "设备在线",
  //   width: "150px",
  //   dataIndex: "device_online_num",
  //   // scopedSlots: { customRender: "device_online_num" },
  //   customRender: (num, text) => {
  //     let content =
  //       num + " " + Number(num / text.device_sum).toFixed(2) * 100 + "%";

  //     // console.log("content", content);
  //     return content;
  //   },
  //   align: "center",
  // },
];
export default {
  name: "DailyListTable",
  components: {},
  props: {
    dataResult: {
      type: Object,
      default: {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    daily_type: {
      type: String,
      default: "0",
    },
  },
  data() {
    this.columns = columns;
    this.columns1 = columns1;
    return {
      columns1: [
        {
          title: "序号",
          scopedSlots: { customRender: "serial" },
          width: "70px",
          align: "center",
        },
        {
          title: "分公司名称", // 表头文字
          width: "110px",
          dataIndex: "bc_name",
          align: "center",
        },
        {
          title: "日报主题",
          width: "200px",
          dataIndex: "daily_title",
          align: "center",
        },
        {
          title: "时间",
          width: "150px",
          dataIndex: "daily_time",
          scopedSlots: { customRender: "daily_time" },
          align: "center",
        },
        // 工地概况日报独有
        // {
        //   title: "工地总数",
        //   width: "150px",
        //   dataIndex: "ws_sum",
        //   scopedSlots: { customRender: "ws_sum" },
        //   align: "center",
        // },
        // {
        //   title: "施工中工地数量",
        //   width: "150px",
        //   dataIndex: "ws_work_num",
        //   scopedSlots: { customRender: "ws_work_num" },
        //   align: "center",
        // },
        // {
        //   title: "工地绑定",
        //   width: "150px",
        //   dataIndex: "ws_bind_num",
        //   scopedSlots: { customRender: "ws_bind_num" },
        //   customRender: (num, text) => {
        //     let content =
        //       Number(Number(num / text.ws_sum) * 100).toFixed(2) + "%";
        //     // Math.round((num / text.ws_sum) * Math.pow(10, 4)) / Math.pow(10, 4);
        //     return (
        //       <span style="color:#1890FF">
        //         {num + " "}
        //         <span class="bgc">{content}</span>
        //       </span>
        //     );
        //   },
        //   align: "center",
        // },
        // {
        //   title: "设备总数",
        //   width: "150px",
        //   dataIndex: "device_sum",
        //   scopedSlots: { customRender: "device_sum" },
        //   align: "center",
        // },
        // {
        //   title: "设备绑定",
        //   width: "150px",
        //   dataIndex: "device_bind_num",
        //   // scopedSlots: { customRender: "device_bind_num" },
        //   customRender: (num, text) => {
        //     let content =
        //       Number(Number(num / text.device_sum) * 100).toFixed(2) + "%";
        //     return (
        //       <span style="color:#1890FF">
        //         {num + " "}
        //         <span class="bgc">{content}</span>
        //       </span>
        //     );
        //   },
        //   align: "center",
        // },
        // {
        //   title: "设备在线",
        //   width: "150px",
        //   dataIndex: "device_online_num",
        //   // scopedSlots: { customRender: "device_online_num" },
        //   customRender: (num, text) => {
        //     let content =
        //       Number(Number(num / text.device_sum) * 100).toFixed(2) + "%";
        //     return (
        //       <span style="color:#1890FF">
        //         {num + " "}
        //         <span class="bgc">{content}</span>
        //       </span>
        //     );
        //   },
        //   align: "center",
        // },
      ],
      expand: false,
      //当前页数，当前条数
      dataList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      selectedRowKeys: [],
      selectedRows: [],
      TableShow: false,
      RibaoShow: false,
      GaiKuangShow: true,
    };
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
  },
  watch: {
    daily_type(n, o) {
      if (n == 0) {
        this.TableShow = false;
        this.RibaoShow = true;
        this.GaiKuangShow = false;
      } else if (n == 1) {
        this.TableShow = false;
        this.RibaoShow = false;
        this.GaiKuangShow = true;
      } else {
        this.TableShow = true;
        this.RibaoShow = false;
        this.GaiKuangShow = false;
      }
    },
  },
  methods: {
    clearSelect() {
      this.selectedRowKeys = [];
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      this.$emit("changeSelect", selectedRows);
    },

    dataLoad(dataResult) {
      this.pageNo = dataResult.page;
      this.total = dataResult.sum;
      this.dataList = dataResult.data;
      // console.log(this.dataList);
    },
    changePage(page, pageSize) {
      this.$emit("changePage", page, pageSize);
    },
    showSizeChange(current, size) {
      this.pageSize = size;
      this.$emit("changePage", current, size);
    },
    //时间戳转时间
    RiQi(sj) {
      let date = new Date(sj * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-"; //年
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-"; //月
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " "; //日
      let h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

      let strDate = Y + M + D;
      return strDate;
    },
  },
};
</script>
<!-- 
 - 当前页面的样式
 - lang="less" 表示使用less语法（主要使用less变量、css嵌套）
 - scoped 表示定义的样式只在当前组件中生效，不影响其他组件
 - 若要写全局生效的css样式，建议写在全局样式文件中global.less（global.less已经在main.js中全局注册）
 -->
<style lang="less" scoped>
.bgc {
  background-color: #dbedfc;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
}
.action a {
  margin-right: 8px;
}
.status1 {
  color: #96959d;
}
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
.ribao {
  text-align: left;
  .riqi {
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .neirong {
    height: 40px;

    display: flex;
  }

  .neirong_left {
    width: 150px;
    border: 1px solid #96959d;
    font-size: 15px;
    padding: 10px;
    background-color: #f2f2f2;
  }
  .neirong_right {
    height: 40px;
    width: 800px;
    border: 1px solid #96959d;
    font-size: 15px;
    padding: 10px;
    border-left: none;
  }
  .neirong_top {
    border-top: none;
  }
  .Num {
    color: blue;
  }
}
</style>
